import {
  getModuleCache,
  getModuleConfig,
  getScreenData,
  hideDialog,
  setModuleCache,
  setScreenData,
  showDialog,
  Store
} from 'cw-ui-core';
import { connect } from 'react-redux';
import AsioEnablementDialog from 'src/components/AsioEnablement/AsioEnablementDialog/AsioEnablementDialog';
import {
  addEntitlements,
  provisionAsio,
  validateProvisioningData
} from 'src/services/AsioService';
import { FEEDBACK_DIALOG_ID } from '../Feedback/FeedbackDialogContainer';

export const ASIO_ENABLEMENT_DIALOG_ID = 'ASIO_ENABLEMENT_DIALOG';

const mapStateToProps = state => {
  return {
    isSuccess: getScreenData(state.screen, ['isSuccess']),
    asioDialogStep: getModuleCache(state.module, ['asioDialogActiveStep'])
  };
};

const mapDispatchToProps = dispatch => {
  const onLinkPSAInstanceClick = async () => {
    const result = await provisionAsio();
    if (result.isSuccess && result.response?.instanceId) {
      dispatch(setScreenData(['isSuccess'], true));
      try {
        await addEntitlements();
      } catch (error) {
        console.error('Error adding entitlements:', error);
      }
    } else {
      dispatch(setScreenData(['isSuccess'], false));
    }

    dispatch(setModuleCache('provisioningResult', result));
    return result;
  };

  const validateAsioProvisioningData = async () => {
    try {
      const result = await validateProvisioningData();
      if (result.isSuccess) {
        return true;
      } else {
        dispatch(setModuleCache('provisioningResult', result));
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onViewCaseClick = linkPSAInstanceResult => {
    const state = Store().getState();
    const salesForceUrl = getModuleConfig(state.module, [
      'SalesForceCaseUrlBase'
    ]);
    const caseId = linkPSAInstanceResult.response?.id;
    const caseUrl = salesForceUrl + caseId;
    window.open(caseUrl, '_blank');
  };

  return {
    onCloseIconClick: () => {
      //set the stepper to first step.
      dispatch(setModuleCache('asioDialogActiveStep', 0));
      dispatch(hideDialog(ASIO_ENABLEMENT_DIALOG_ID));
      dispatch(setModuleCache('provisioningResult', null));
    },
    onNeedSupportClick: asioStep => {
      dispatch(setModuleCache('asioDialogActiveStep', asioStep));
      dispatch(hideDialog(ASIO_ENABLEMENT_DIALOG_ID));
      dispatch(showDialog(FEEDBACK_DIALOG_ID));
    },
    onLinkPSAInstanceClick,
    validateAsioProvisioningData,
    onViewCaseClick
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsioEnablementDialog);
