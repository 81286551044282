import { GET, getUrl, POST } from 'src/services/ApiService';

const AsioSelfService = () => {
  const apiUrl = () => getUrl('home', '/partner');
  const apiCompanyUrl = () => getUrl('home', '/company');
  const asioProvisioningUrl = () => getUrl('home', '/asioselfservice');

  const getPartnerRegion = async () => {
    return await GET(`${apiUrl()}/region`, true, '1.0');
  };

  const getCheckPartner = async () => {
    return await GET(`${apiCompanyUrl()}/checkPartner`, true, '1.0');
  };

  const provisionAsio = async provisioningDetails => {
    return await POST(
      `${asioProvisioningUrl()}/provisioning`,
      provisioningDetails
    );
  };

  const submitFeedback = async feedbackData => {
    return await POST(`${asioProvisioningUrl()}/createcase`, feedbackData);
  };

  const validate = async provisioningData => {
    return await POST(`${asioProvisioningUrl()}/validate`, provisioningData);
  };

  const addEntitlements = async () => {
    return await POST(`${asioProvisioningUrl()}/addentitlements`);
  };

  const getWhatsNextData = async () => {
    return await GET(`${asioProvisioningUrl()}/whatsnext`, true, '1.0');
  };

  const getVideoTutorialsData = async () => {
    return await GET(`${asioProvisioningUrl()}/videotutorials`, true, '1.0');
  };

  return {
    getPartnerRegion,
    getCheckPartner,
    provisionAsio,
    submitFeedback,
    validate,
    addEntitlements,
    getWhatsNextData,
    getVideoTutorialsData
  };
};

export default AsioSelfService();
